import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import Message from 'components/content/Message';
export const _frontmatter = {
  "path": "/developer/react-router/",
  "date": "2016-01-17",
  "title": "REACT ROUTER",
  "author": "admin",
  "tags": ["development", "javascript", "react.js"],
  "featuredImage": "feature.jpg",
  "excerpt": "React Router is the most popular routing library for React, which synchronizes your UI with the URL. It is built on top of React and helps you to build new screens quickly."
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h2>{`Why React Router?`}</h2>
    <p>{`Without a router library, we'd have to make our URL parsing a lot smarter, and we would end up with a lot of code to figure out which branch of nested components to be rendered at any given URL.`}</p>
    <h2>{`Installation`}</h2>
    <p>{`Install react-router usingnpm:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{` npm install react-router --save
`}</code></pre>
    <p>{`Use `}<strong parentName="p">{`--save`}</strong>{` flag to save the dependency to the `}<inlineCode parentName="p">{`package.json`}</inlineCode>{` file.`}</p>
    <h2>{`Using React Router`}</h2>
    <p>{`Let's write a React component with ES6 syntax:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import { Route } from 'react-router';

const routes = (
  <Route handler={App} path="/">
    <Route name="about" handler={About} />
  </Route>
);
`}</code></pre>
    <p>{`We import `}<strong parentName="p">{`Route`}</strong>{` component from `}<strong parentName="p">{`react-router`}</strong>{` library. `}</p>
    <h3>{`Custom Path`}</h3>
    <p>{`We can specify a custom path like this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import { Route } from 'react-router';

const routes = (
  <Route handler={App} path="/">
    <Route name="about" path="my-about" handler={About} />
  </Route>
);
`}</code></pre>
    <p>{`About page is now available at `}<strong parentName="p">{`/my-about`}</strong>{`.`}</p>
    <h3>{`Default Route`}</h3>
    <p><inlineCode parentName="p">{`DefaultRoute`}</inlineCode>{` can be used to point to home page.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import { Route, DefaultRoute } from 'react-router';

const routes = (
  <Route handler={App} path="/">
    <DefaultRoute handler={Home} />
    <Route name="about" path="my-about" handler={About} />
  </Route>
);
`}</code></pre>
    <p>{`Home page is now available at /.`}</p>
    <h3>{`NotFound Route`}</h3>
    <p><inlineCode parentName="p">{`NotFoundRoute`}</inlineCode>{` can be used to point to home page.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import { Route } from 'react-router';

const routes = (
  <Route handler={App} path="/">
    <Route name="about" handler={About} />
    <NotFoundRoute handler={NotFound}/>
  </Route>
);
`}</code></pre>
    <p>{`Home page is now available at `}<strong parentName="p">{`/`}</strong>{`.`}</p>
    <h3>{`Nesting`}</h3>
    <p>{`You can nest routes inside another.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import { Route } from 'react-router';

const routes = (
  <Route handler={App} path="/">
    <Route name="users" handler={Users}>
      <Route name="recent-users" path="recent"
      handler={RecentUsers} />
    </Route>
  </Route>
);
`}</code></pre>
    <p>{`Recent users available at `}<strong parentName="p">{`/users/recent`}</strong>{`.`}</p>
    <h3>{`Request Parameters`}</h3>
    <p>{`Request Parameters are added to the router with a colon, for example `}<strong parentName="p">{`/user/:userId`}</strong>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import { Route } from 'react-router';

const routes = (
  <Route handler={App} path="/">
    <Route name="users" handler={Users}>
    <Route name="user" path="/user/:userId" handler={User} />
    </Route>
  </Route>
);
`}</code></pre>
    <p>{`User 1234 is now available at `}<strong parentName="p">{` /user/1234`}</strong>{`.`}</p>
    <p>{`Multiple request parameters can added like follows:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`<Route name="user-message"
  path="/user/:userId/message/:messageId"
  handler={User} />
`}</code></pre>
    <p>{`This will resolve into `}<strong parentName="p">{`/users/123/message/456`}</strong>{`.`}</p>
    <h3>{`Redirect Routes`}</h3>
    <p><inlineCode parentName="p">{`Redirect`}</inlineCode>{` can be used for redirect routes.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import { Route, Redirect } from 'react-router';

const routes = (
  <Route handler={App} path="/">
    <Route name="about" handler={About} />
    <Redirect from="company" to="about" />
  </Route>
);
`}</code></pre>
    <p>{`Now `}<strong parentName="p">{`/company`}</strong>{` will be redirected to `}<strong parentName="p">{`/about`}</strong>{`.`}</p>
    <p>{`To redirect routes with parameters:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import { Route, Redirect } from 'react-router';

const routes = (
  <Route handler={App} path="/">
    <Redirect from="/user/me" to="user" params={{userId: MY_ID}} />
    <Route name="user" path="/user/:userId" handler={User} />
  </Route>
);
`}</code></pre>
    <p>{`Notice the order of the routes above, it is swapped.`}</p>
    <h3>{`Including External Routes`}</h3>
    <p>{`We can import external routes like following:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import { Route, Redirect } from 'react-router';
import aboutRoutes from 'about/routes';

const routes = (
  <Route handler={App} path="/">
    <Redirect from="/user/me" to="user" params={{userId: MY_ID}} />
    <Route name="user" path="/user/:userId" handler={User} />
    {aboutRoutes}
  </Route>
);
`}</code></pre>
    <h2>{`Running The Router`}</h2>
    <p>{`We can run the router in three different ways:`}</p>
    <ol>
  <li>Using Hashes</li>
  <li>Using HTML5 History</li>
  <li>Using Universal Rendering</li>
    </ol>
    <p>{`Let's look at each of these options.`}</p>
    <h3>{`Using hashes`}</h3>
    <p>{`We can pass in the routes and a callback to the `}<strong parentName="p">{`run`}</strong>{` method of the Router.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import React from 'react';
import Router from 'react-router';

Router.run(routes, (Handler) => {
  React.render(<Handler/>, document.body);
});
`}</code></pre>
    <p>{`The location will now be for instance `}<strong parentName="p">{`http://localhost/#/about`}</strong>{`.`}</p>
    <h3>{`Using HTML5 History`}</h3>
    <p>{`Here we can pass in the routes, `}<strong parentName="p">{`Router.HistoryLocation`}</strong>{` and a callback to the `}<strong parentName="p">{`run`}</strong>{` method of the Router.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import React from 'react';
import Router from 'react-router';

Router.run(routes, (Handler) => {
  React.render(<Handler/>, document.body);
});
`}</code></pre>
    <p>{`The location will now be for instance `}<strong parentName="p">{`http://localhost/about`}</strong>{`. Notice there is no `}<strong parentName="p">{`#`}</strong>{` in the URL anymore.`}</p>
    <h3>{`Using Universal Rendering`}</h3>
    <p>{`With universal rendering, we render the HTML to the client from the server.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import React from 'react';
import Router from 'react-router';

app.serve((req, res) => {
  Router.run(routes, (Handler) => {
    React.render(<Handler/>, document.body);
  });
});
`}</code></pre>
    <h2>{`Rendering the Routes`}</h2>
    <p>{`For rendering the routes, we can use `}<inlineCode parentName="p">{`RouterHandler`}</inlineCode>{` object of the React Router library.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import React from 'react';
import { RouterHandler } from 'react-router';

class App extends React.Component {
  render() {
    return (
      <div>
        <h1>Hello World!</h1>
        <RouterHandler />
      </div>
    );
  }
}
`}</code></pre>
    <p>{`Now the `}<strong parentName="p">{`App`}</strong>{` component will render the matched router.`}</p>
    <h2>{`Accessing Router Methods`}</h2>
    <p>{`Let's quickly have a look at how we can access the router methods in ES5, ES6 and ES7.`}</p>
    <h3>{`ES5`}</h3>
    <p>{`With ES5 syntax:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`var React = require('react');

var User = React.createClass({
  contextTypes: {
    router: React.PropTypes.func
  },
  render: function () {
    return <h1>Hello World</h1>;
  }
};
`}</code></pre>
    <p>{`Router methods will be available in render `}<inlineCode parentName="p">{`self.context.router`}</inlineCode>{`.`}</p>
    <h3>{`ES6`}</h3>
    <p>{`With ES6 syntax:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import React from 'react';

class User extends React.Component {
  render() {
    return <h1>Hello World</h1>;
  }
}

User.contextTypes = {
  router: React.PropTypes.func
};
`}</code></pre>
    <p>{`Router methods will be also available in render `}<inlineCode parentName="p">{`self.context.router`}</inlineCode>{`.`}</p>
    <h3>{`ES7`}</h3>
    <p>{`With ES7 syntax:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import React from 'react';

class User extends React.Component {
  static contextTypes = {
    router: React.PropTypes.func
  }

  render() {
    return <h1>Hello World</h1>;
  }
}
`}</code></pre>
    <p>{`Router methods will be also available in render `}<inlineCode parentName="p">{`self.context.router`}</inlineCode>{`.`}</p>
    <h2>{`Available Router Methods`}</h2>
    <h3>{`Retrieving Parameters`}</h3>
    <p>{`We can access the parameters with the `}<inlineCode parentName="p">{`getCurrentParams`}</inlineCode>{` method.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import React from 'react';

class User extends React.Component {
  static contextTypes = {
    router: React.PropTypes.func
  }

  render() {
    const user = this.context.router.getCurrentParams().userId;
    return <h1>Hello user {user}</h1>;
  }
}
`}</code></pre>
    <p>{`The parameter will be retrieved from route `}<strong parentName="p">{`/user/:userId`}</strong>{`.`}</p>
    <h3>{`Retrieving Query Parameters`}</h3>
    <p>{`We can access the query parameters with the `}<inlineCode parentName="p">{`getCurrentQuery`}</inlineCode>{` method.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import React from 'react';

class User extends React.Component {
  static contextTypes = {
    router: React.PropTypes.func
  }

  render() {
    const message = this.context.router.getCurrentQuery().message;
    return <h1>{message}</h1>;
  }
}
`}</code></pre>
    <p>{`The query parameter will be retrieved from route `}<strong parentName="p">{`/user/1234?message=Hello%20World`}</strong>{`.`}</p>
    <h3>{`Retrieving Current Routes`}</h3>
    <p>{`We can access the current routes with the `}<inlineCode parentName="p">{`getCurrentRoutes`}</inlineCode>{` method.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import React from 'react';

class User extends React.Component {
  static contextTypes = {
    router: React.PropTypes.func
  }

  render() {
    const routes = this.context.router.getCurrentRoutes();
    ...
  }
}
`}</code></pre>
    <p>{`This will return an array of routes in nesting order.`}</p>
    <h2>{`Fetching Data`}</h2>
    <p>{` To fetch data with fetch API, let's write our own function helper.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`function fetchData(state) {
  return Promise.all(state.routes.filter((route) => {
    return route.handler.fetchData;
  }).map((route) => {
    return route.handler.fetchData(state.params, state.query);
  });
}
`}</code></pre>
    <p>{`Now we can call this function inside `}<strong parentName="p">{`Router.run()`}</strong>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import React from 'react';
import Router from 'react-router';

Router.run(routes, (Handler, state) => {
  fetchData(state).then(() => {
    React.render(<Handler/>, document.body);
  });
});
`}</code></pre>
    <p>{`We can improve on this by adding fetch functionality to our own model.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import React from 'react';

class User extends React.Component {
  static fetchData(params) {
    return new Promise((resolve) => {
      MyApi.loadSomething(() => {
        resolve();
      });
    });
  }
  ...
}
`}</code></pre>
    <h2>{`Navigating Between Routes`}</h2>
    <p>{`When using React Router, avoid using `}<inlineCode parentName="p">{`href`}</inlineCode>{` for navigation.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import React from 'react';

class User extends React.Component {
  render() {
    // Don't do this!
    return (
      <a href="/about">About</a>
    );
  }
}
`}</code></pre>
    <h3>{`Using Link Component`}</h3>
    <p>{`React Router provides a component called `}<inlineCode parentName="p">{`Link`}</inlineCode>{` which you can use instead.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import React from 'react';
import { Link } from 'react-router';

class User extends React.Component {
  render() {
    return (
      <Link to="about">About</Link>
    );
  }
}
`}</code></pre>
    <p>{`This will generate a link - `}<strong parentName="p">{`<a href="/about"`}{`>`}{`About`}{`<`}{`/a>`}</strong>{`.`}</p>
    <h3>{`Adding Parameters`}</h3>
    <p>{`To add parameters, you can provide `}<inlineCode parentName="p">{`params`}</inlineCode>{` attribute to the Link component. And for query parameters, you can add `}<inlineCode parentName="p">{`query`}</inlineCode>{` attribute.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import React from 'react';
import { Link } from 'react-router';

class About extends React.Component {
  render() {
    return (
      <Link to="user" params={{userId: 1234}}>User 1234</Link>
      <Link to="contact" query={{message: 'Hi'}}>Say hi</Link>
    );
  }
}
`}</code></pre>
    <h3>{`Using makeHref`}</h3>
    <p>{`The `}<inlineCode parentName="p">{`makeHref`}</inlineCode>{` function will generate a link for you.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import React from 'react';

class About extends React.Component {
  static contextTypes = {
    router: React.PropTypes.func
  }

  render() {
    const link = this.context.router.makeHref(
      'user', { userId: 1234 }, { message: 'Hi'});

    return (
      <a href={link}>About</a>
    );
  }
}
`}</code></pre>
    <p>{`The generated link will have a parameter as well as query params.`}</p>
    <h3>{`Using transitionTo`}</h3>
    <p>{`To fire a transition to anotehr URL, you can use `}<inlineCode parentName="p">{`transitioTo`}</inlineCode>{` before React Router version 1.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import React from 'react';

class About extends React.Component {
  static contextTypes = {
    router: React.PropTypes.func
  }

  onSubmit() {
    this.context.router.transitionTo('user',
      {userId: 1234},
      {message: 'Hi'});
  }
  ...
}
`}</code></pre>
    <p>{`This will transition to `}<strong parentName="p">{`/user/1234?message=Hi`}</strong></p>
    <Message type="warn" title="" content="This will only cover ReactRouter versions before 1.0" mdxType="Message" />
    <h3>{`Using replaceWith`}</h3>
    <p>{`Without creating a new history entry, `}<inlineCode parentName="p">{`replaceWith`}</inlineCode>{` navigates user to the given location.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import React from 'react';

class User extends React.Component {
  static contextTypes = {
    router: React.PropTypes.func
  }

  onSubmit() {
    this.context.router.replaceWith('about');
  }
  ...
}
`}</code></pre>
    <h3>{`Using goBack`}</h3>
    <p>{`To go back one step in history, we can use `}<inlineCode parentName="p">{`goBack`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import React from 'react';

class User extends React.Component {
  static contextTypes = {
    router: React.PropTypes.func
  }

  onSubmit() {
    this.context.router.goBack();
  }
  ...
}
`}</code></pre>
    <h3>{`Using goForward`}</h3>
    <p>{`To go forward one step in history, we can use `}<inlineCode parentName="p">{`goForward`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import React from 'react';

class User extends React.Component {
  static contextTypes = {
    router: React.PropTypes.func
  }

  onSubmit() {
    this.context.router.goForward();
  }
  ...
}
`}</code></pre>
    <h3>{`Using go`}</h3>
    <p>{`To go backward `}<inlineCode parentName="p">{`n`}</inlineCode>{` steps in history.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import React from 'react';

class User extends React.Component {
  static contextTypes = {
    router: React.PropTypes.func
  }

  onSubmit() {
    this.context.router.go(-2);
  }
  ...
}
`}</code></pre>
    <p>{`This go 2 steps back. If you want to go forward, you will have to use positive arguments. eg:- `}<strong parentName="p">{`this.context.router.go(2)`}</strong></p>
    <h2>{`Lifecycle Methods`}</h2>
    <h3>{`Using willTransitionTo and willTransitionFrom`}</h3>
    <p>{`To redirect to a given location.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import React from 'react';

class User extends React.Component {
  static willTransitionTo(transition) {
    if (!auth.loggedIn()) {
      transition.redirect('/login',
      {},
      {'redirect' : transition.path});
    }
  }
  ...
}
`}</code></pre>
    <Message type="warn" title="" content="This will only cover ReactRouter versions before 1.0" mdxType="Message" />
    <h2>{`Changes in Version 1.0`}</h2>
    <h3>{`Componet not handler`}</h3>
    <p>{`In version 1.0 `}<inlineCode parentName="p">{`handler`}</inlineCode>{` has been replaced with `}<inlineCode parentName="p">{`component`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import { Route } from 'react-router';

const routes = (
  <Route handler={App} path="/">
    <Route name="about" handler={About} />
  </Route>
);

// version 1.0
const routes = (
  <Route handler={App} path="/">
    <Route name="about" component={About} />
  </Route>
);
`}</code></pre>
    <h3>{`Props not RouteHandler`}</h3>
    <p>{`Also `}<inlineCode parentName="p">{`RouteHandler`}</inlineCode>{` was changed to `}<inlineCode parentName="p">{`props`}</inlineCode></p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import React from 'react';

class App extends React.Component {
  render() {
    return (
      <RouterHandler />
    );
  }
}

// version 1.0
class App extends React.Component {
  render() {
    return (
      {this.props.children}
    );
  }
}
`}</code></pre>
    <h3>{`No more named routes`}</h3>
    <p>{`Named routes were also taken out`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`<Route handler={App} path="/">
  <Route name="user" path="/user/:userId" handler={User} />
</Route>

// version 1.0
<Route component={App} path="/">
  <Route path="/user/:userId" component={User} />
</Route>
`}</code></pre>
    <h3>{`Catch all route`}</h3>
    <p>{`The Not found route becomes catch all.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`<NotFoundRoute handler={NotFound} />

// version 1.0
<Route path="*" component={NotFound} />
`}</code></pre>
    <h3>{`Redirect routes`}</h3>
    <p>{`Redirect routes now have params in the URL.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`<Route handler={App} path="/">
  <Redirect from="/user/me" to="user" params={userId: '1234'} />
  <Route name="user" path="/user/:userId" handler={User} />
</Route>

// version 1.0
<Route component={App} path="/">
  <Redirect from="/user/me" to="/user/1234" />
  <Route path="/user/:userId" component={User} />
</Route>
`}</code></pre>
    <h3>{`DefaultRoute not IndexRoute`}</h3>
    <h3>{`IndexRoute has been replaced with DefaultRoute`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`<Route handler={App} path="/">
  <DefaultRoute handler={Home} />
  <Route name="about" handler={About} />
</Route>

// version 1.0
<Route component={App} path="/">
  <IndexRoute component={Home} />
  <Route path="about" component={About} />
</Route>
`}</code></pre>
    <h3>{`Mutliple components`}</h3>
    <p>{`Instead of passing an object of multiple components, in 1.0, we use multiple components.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import { Route } from 'react-router';
const routes = (
  <Route component={App}>
    <Route path="users" components={{main: Users,
                                     sidebar: UsersSidebar}}/>
</Route> );

// version 1.0
import React from 'react';
class App extends React.Component {
  render() {
    return (
      <div>
        <div className="Main">
          {this.props.main}
        </div>
        <div className="Sidebar">
          {this.props.sidebar}
        </div>
      </div>
    );
  }
}
`}</code></pre>
    <h3>{`Running the router`}</h3>
    <p>{`The we run the router has changed as well.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`Router.run(routes, (Handler) => {
  React.render(<Handler/>, document.body);
});

// version 1.0
import { history } from 'react-router/lib/HashHistory';
React.render((
  <Router history={history}>
    {routes}
  </Router>
), document.body);
`}</code></pre>
    <h3>{`History options`}</h3>
    <p>{`The history options have chnages as well.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import { history } from 'react-router/lib/HashHistory';
import { history } from 'react-router/lib/BrowserHistory';
import { history } from 'react-router/lib/MemoryHistory';
`}</code></pre>
    <h3>{`createHref not makeHref`}</h3>
    <p>{`Instead of using makeHref, now we have params in the link.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`const link = this.context.router.makeHref('user', { userId: 1234 },
                                          { message: 'Hi'});

// version 1.0
const link = this.context.router.createHref('/user/1234', { message: 'Hi'});
`}</code></pre>
    <h3>{`Link component`}</h3>
    <p>{`Link components also incorporate params in the link.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`<Link to="user" params={{userId: MY_ID}}>John Doe</Link>

// version 1.0
<Link to={'/users/'} + MY_ID}>John Doe</Link>
`}</code></pre>
    <h3>{`transitionTo`}</h3>
    <p>{`The parameters have changed for `}<inlineCode parentName="p">{`transitionTo`}</inlineCode>{` in version 1.0.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`// transitionTo(pathname, params, query)
this.context.router.transitionTo('user', { userId: 1234 }, { message: 'Hi' });

// version 1.0

// transitionTo(pathname, query, state)
this.context.router.transitionTo('/user/1234', { message: 'Hi' });
`}</code></pre>
    <h3>{`willTransitionTo and willTransitionForm`}</h3>
    <p>{`Instead of above functions, version 1.0 uses `}<inlineCode parentName="p">{`onEnter`}</inlineCode>{` and `}<inlineCode parentName="p">{`onLeave`}</inlineCode>{` respectively.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`function requireAuth(nextState, transition) {
  ...
}

function handler(nextState, transition) {
  ...
}

const routes = (
  <Route component={App} path="/">
    <Route path="about" component={About} onEnter={requireAuth}
      onLeave={handler}/>
  </Route>
);
`}</code></pre>
    <h3>{`routerWillLeave`}</h3>
    <p>{`Instead of `}<inlineCode parentName="p">{`willTransitionFrom`}</inlineCode>{`, you can use `}<inlineCode parentName="p">{`routerWillLeave`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`class User extends React.Component {
  static willTransitionFrom(transition) {
    ...
  }
  ...
}

// version 1.0

class User extends React.Component {
  static routerWillLeave(nextState, router) {
    ...
  }
  ...
}
`}</code></pre>
    <h2>{`State`}</h2>
    <p>{`Lastly, let's look at what has changed with the state in version 1.`}</p>
    <table>
  <tr>
    <th>0.x</th>
    <th>1.x</th>
  </tr>
  <tr>
    <td>getPath()</td>
    <td>location.pathname + location.query</td>
  </tr>
  <tr>
    <td>getPathname()</td>
    <td>location.pathname</td>
  </tr>
  <tr>
    <td>getParams()</td>
    <td>params</td>
  </tr>
  <tr>
    <td>getQuery()</td>
    <td>location.query</td>
  </tr>
  <tr>
    <td>getRoutes()</td>
    <td>routes</td>
  </tr>
  <tr>
    <td>isActive(to, params, query)</td>
    <td>history.isActive(pathname, query)</td>
  </tr>
    </table>
    <h2>{`Conclusion`}</h2>
    <p>{`This article covered most of the routing scenarios you may come across and hopefully, you will find routing with React a breeze.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      